var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.settings.Layout.mini,"app":"","clipped":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list-item',{staticClass:"elevation-24",on:{"click":function($event){$event.stopPropagation();return _vm.updateDomAndSettings('mini')}}},[_c('v-list-item-action',[(_vm.settings.Layout.mini)?_c('v-icon',[_vm._v("arrow_forward")]):_c('v-icon',[_vm._v("arrow_back")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.core.Layout.Collapse")))])],1)],1)]},proxy:true}]),model:{value:(_vm.settings.Layout.drawer),callback:function ($$v) {_vm.$set(_vm.settings.Layout, "drawer", $$v)},expression:"settings.Layout.drawer"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":"","nav":"","dark":"","color":"#212121"}},[_c('v-list-item',{class:_vm.settings.Layout.mini && 'px-0',attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"large":""}},[_vm._v("person")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.username))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.email))])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.routes),function(route){return _c('v-list-item',{key:route.name,attrs:{"to":("" + (route.path))}},[(_vm.settings.Layout.mini)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(_vm._s(route.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(route.name)))])])],1):_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(route.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(route.name)))])],1)],1)}),1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/users"}},[(_vm.settings.Layout.mini)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("group")])]}}],null,false,3527340433)},[_c('span',[_vm._v(_vm._s(_vm.$t("components.core.Layout.Users")))])])],1):_c('v-list-item-action',[_c('v-icon',[_vm._v("group")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.core.Layout.Users")))])],1)],1),_c('v-list-item',{attrs:{"to":"/settings"}},[(_vm.settings.Layout.mini)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("settings")])]}}],null,false,1926182315)},[_c('span',[_vm._v(_vm._s(_vm.$t("components.core.Layout.Settings")))])])],1):_c('v-list-item-action',[_c('v-icon',[_vm._v("settings")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.core.Layout.Settings")))])],1)],1)],1)],1),_c('v-app-bar',{attrs:{"color":"black","dark":"","app":"","clipped-left":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.updateDomAndSettings('drawer')}}}),_c('v-toolbar-title',{staticClass:"font-weight-bold"},[_vm._v("ALCALI")]),_c('v-spacer'),_c('v-expand-transition',[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.expand_search),expression:"expand_search"}],staticClass:"mx-auto search",attrs:{"flat":"","hide-details":"","label":_vm.$t('components.core.Layout.Search'),"solo-inverted":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchBar.apply(null, arguments)}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){_vm.expand_search = !_vm.expand_search}}},[_c('v-icon',[_vm._v("search")])],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"color":_vm.notif_nb > 0 ? 'primary' : 'transparent',"overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [(_vm.notif_nb > 0)?_c('span',[_vm._v(_vm._s(_vm.notif_nb))]):_vm._e()]},proxy:true}],null,true)},[_c('v-icon',_vm._g({on:{"click":function($event){_vm.notif_nb = 0}}},on),[_vm._v("notifications")])],1)]}}]),model:{value:(_vm.notif_menu),callback:function ($$v) {_vm.notif_menu=$$v},expression:"notif_menu"}},[_c('v-card',{attrs:{"min-width":"500px","max-width":"500px"}},[_c('v-list',{attrs:{"max-height":"700px"}},[(_vm.messages.length === 0)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t("components.core.Layout.NoNotifications")))])],1)],1):_vm._e(),_vm._l((_vm.messages),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.link}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"dark":"","color":item.color,"size":"62"}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.tag))])],1)],1)})],2),_c('v-card-actions',{directives:[{name:"show",rawName:"v-show",value:(_vm.messages.length > 0),expression:"messages.length > 0"}]},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.messages = []}}},[_vm._v(_vm._s(_vm.$t("components.core.Layout.Clear")))])],1)],1)],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","offset-x":"","close-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.updateDomAndSettings('dark')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.core.Layout.ToggleTheme")))])],1),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("components.core.Layout.Logout")))])],1)],1)],1)],1),_c('v-main',[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }